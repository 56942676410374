import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { DeepExtractType } from 'ts-deep-extract-types';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import { Container, Section } from '../components/generic/View';
import { Heading2 } from '../components/generic/Text';
import Content from '../components/RichContent';

type Props = DeepExtractType<
  GatsbyTypes.ImprintPageTemplateQuery,
  ['markdownRemark', 'frontmatter']
>;

export const ImprintPageTemplate: FC<Props> = ({
  imprint_section,
  footer_section
}) => {
  return (
    <>
      <Section withGradient>
        <Container>
          <Heading2 borderBottom className="mb-5 md:mb-12.5">
            {imprint_section?.heading}
          </Heading2>
          <Content markdown={imprint_section?.rich_text} />
        </Container>
      </Section>
      <Footer richText={footer_section?.rich_text || ''} />
    </>
  );
};

const ImprintPage: FC<{ data: GatsbyTypes.ImprintPageTemplateQuery }> = ({
  data
}) => (
  <Layout
    pageTitle={data.markdownRemark?.frontmatter?.pageTitle || ''}
    metaTagDescription={
      data.markdownRemark?.frontmatter?.meta_tag_description || ''
    }
  >
    <ImprintPageTemplate
      pageTitle={data.markdownRemark?.frontmatter?.pageTitle}
      meta_tag_description={
        data.markdownRemark?.frontmatter?.meta_tag_description
      }
      imprint_section={data.markdownRemark?.frontmatter?.imprint_section}
      footer_section={data.markdownRemark?.frontmatter?.footer_section}
    />
  </Layout>
);

export default ImprintPage;

export const pageQuery = graphql`
  query ImprintPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "imprint-page" } }) {
      frontmatter {
        pageTitle
        meta_tag_description
        imprint_section {
          heading
          rich_text
        }
        footer_section {
          rich_text
        }
      }
    }
  }
`;
