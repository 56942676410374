import classNames from 'classnames';
import React, { FC } from 'react';

export const Heading1: FC<{ className?: string }> = (props) => {
  return (
    <h1
      className={`font-serif
                  text-xl md:text-5xl
                  leading-[1.875rem] md:leading-[3.938rem]
                  font-bold text-white ${props.className || ''}`}
    >
      {props.children}
    </h1>
  );
};

export const Heading2: FC<{ className?: string; borderBottom?: boolean }> = (
  props
) => {
  return (
    <div className={props.className || ''}>
      <h2
        className={classNames(
          `font-serif font-normal text-black`,
          props.borderBottom && 'mb-2 md:mb-8',
          `
                    text-base md:text-[2.625rem]
                    leading-6 md:leading-16`
        )}
      >
        {props.children}
      </h2>
      {props.borderBottom && (
        <div className="bg-black w-full h-[0.125rem]"></div>
      )}
    </div>
  );
};

export const Heading3: FC<{ className?: string }> = (props) => {
  return (
    <h3
      className={`font-serif font-normal ${props.className || ''}
                  text-sm md:text-[1.625rem]
                  leading-[1.313rem] md:leading-10`}
    >
      {props.children}
    </h3>
  );
};

export const Heading4: FC<{ className?: string; borderBottom?: boolean }> = ({
  className,
  borderBottom,
  children
}) => {
  return (
    <div className={classNames(`w-full md:w-[33.75rem]`, className)}>
      <h4
        className={classNames(
          `font-serif font-normal 
                    text-[0.813rem] md:text-[1.375rem]
                    leading-[0.938rem] md:leading-relaxed`,
          borderBottom && 'mb-2.5 md:mb-3'
        )}
      >
        {children}
      </h4>
      {borderBottom && (
        <div className="bg-black h-px md:h-[2px] w-24 md:w-full"></div>
      )}
    </div>
  );
};

export const Paragraph: FC<{
  className?: string;
  isSpan?: boolean;
  marginBottom?: boolean;
  bold?: boolean;
}> = (props) => {
  const classList = classNames(
    `font-sans`,
    props.className,
    props.marginBottom && 'mb-1 md:mb-3',
    props.bold ? 'font-bold' : 'font-normal',
    `
                    text-sm md:text-[1.125rem]
                    leading-[1.313rem] md:leading-7`
  );

  if (props.isSpan) {
    return <span className={classList}>{props.children}</span>;
  } else {
    return <p className={classList}>{props.children}</p>;
  }
};

export const ParagraphLarge: FC<{
  className?: string;
  isSpan?: boolean;
  marginBottom?: boolean;
}> = (props) => {
  const classList = classNames(
    `font-serif font-normal`,
    props.className,
    props.marginBottom && 'mb-3',
    `text-sm md:text-[1.625rem] leading-6 md:leading-[2.438rem]`
  );

  if (props.isSpan) {
    return <span className={classList}>{props.children}</span>;
  } else {
    return <p className={classList}>{props.children}</p>;
  }
};

export const ParagraphExtraLarge: FC<{ className?: string }> = (props) => {
  return (
    <p
      className={`font-serif font-normal ${props.className || ''}
                  text-base md:text-[2.625rem]
                  leading-6 md:leading-16`}
    >
      {props.children}
    </p>
  );
};
